import { Button } from '@mui/material'
import { formatDate } from '../../utils/datetimeUtils'
import { grey } from '@mui/material/colors'
import { useCounterPartyDealsLazyQuery } from '../../graphql/codegen/hooks'
import { useIsKlarityEmployee } from '../../hooks/useCurrentUser'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ComponentLoadingOverlay from '../ComponentLoadingOverlay'
import Modal from '../Modal'
import QueryMenu from '../QueryMenu'
import React, { FC, ReactNode, useState } from 'react'
import RenameFile from '../RenameFile'
import WithTooltip from '../WithTooltip'
import css from './style.module.scss'

// types

type _PageHeaderProps = {
  alias?: any
  breadcrumbSource: string
  contentRight: ReactNode
  counterpartyId: string | undefined
  dealIsFinalized?: boolean
  hasDealAccess?: boolean
  name?: any
  onBack: () => void
  sourceInfo?: any
  subTitle: string | ReactNode
}

// components

const PageHeader: FC<_PageHeaderProps> = ({
  alias,
  breadcrumbSource,
  contentRight,
  counterpartyId,
  dealIsFinalized,
  hasDealAccess,
  name,
  onBack,
  sourceInfo,
  subTitle
}) => {
  const isKlarityEmployee = useIsKlarityEmployee()
  const { dealId, documentId } = useParams<{ dealId?: string; documentId?: string }>()
  const [modalLoading, setModalLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [modalOption, setModalOption] = useState('')
  const { t } = useTranslation()

  const [getCounterPartyDeals, { data: queryData, loading: queryLoading }] = useCounterPartyDealsLazyQuery({ fetchPolicy: 'no-cache' })

  const openModal = () => {
    setIsOpen(true)
    setModalOption(`Rename ${dealId ? t('Deal') : 'Document'}`)
  }

  const closeModal = () => {
    if (!modalLoading) {
      setIsOpen(false)
      setModalOption('')
    }
  }

  const uploadDate = sourceInfo?.created_at ? formatDate(sourceInfo?.created_at) : undefined
  const uploadedBy = sourceInfo?.created_by?.user_name?.includes('klaritylaw.com')
    ? isKlarityEmployee
      ? sourceInfo?.created_by?.user_name
      : 'Klarity'
    : sourceInfo?.created_by?.user_name
  const titleTooltipContent = (
    <>
      {alias || name}
      {sourceInfo?.integration_type && <div>{`Source: ${sourceInfo?.integration_type}`}</div>}
      {sourceInfo?.integration_type === 'MANUAL' && uploadedBy && <div>{`Uploaded by: ${uploadedBy}`}</div>}
      {uploadDate && <div>{`Upload Date: ${uploadDate}`}</div>}
      {!dealIsFinalized && <div>{`(Click to rename${dealId ? ` ${t('Deal')}` : documentId ? ' Document' : ''})`}</div>}
    </>
  )

  return (
    <>
      <div className={css.pageHeader}>
        <div className={css.contentLeft}>
          <span className={css.breadcrumbSource}>
            <Button onClick={onBack} sx={{ minWidth: 'auto', p: '1px 0 0', '&:hover': { background: 'none' } }}>
              {breadcrumbSource}
            </Button>
            &nbsp;<span>/</span>&nbsp;
          </span>

          {counterpartyId && hasDealAccess ? (
            <div onClick={() => getCounterPartyDeals({ variables: { counterpartyId, size: 20 } })}>
              <WithTooltip content={`View customer’s ${t('deals')}`}>
                <QueryMenu
                  label={String(subTitle)}
                  loading={queryLoading}
                  placement="right"
                  queryData={queryData}
                  queryFunction={getCounterPartyDeals}
                  queryItemName={t('deals')}
                  queryPlaceholder={`Search for ${t('deal')}…`}
                  queryVars={{ required: { counterpartyId }, optional: {} }}
                  style={{ display: 'flex', height: '27px' }}
                  styleOffset={[20, 10]}
                />
              </WithTooltip>
            </div>
          ) : (
            subTitle && <span className={css.subTitle}>{subTitle}</span>
          )}

          <WithTooltip content={titleTooltipContent} style={{ display: 'flex', overflow: 'hidden' }}>
            <Button
              onClick={dealIsFinalized ? undefined : openModal}
              sx={{
                color: grey[900],
                cursor: dealIsFinalized ? 'text' : 'pointer',
                display: 'block',
                m: '1px 2px 1px 8px',
                overflow: 'hidden',
                p: 0,
                textOverflow: 'ellipsis',
                userSelect: 'initial',
                whiteSpace: 'nowrap',
                '&:hover': { background: 'none' }
              }}
            >
              {alias || name}
            </Button>
          </WithTooltip>
        </div>

        <div className={css.contentRight}>{contentRight}</div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={closeModal} title={modalOption}>
        {modalLoading && <ComponentLoadingOverlay loading={modalLoading} message={loadingMessage} />}

        <div className={css.modal}>
          <RenameFile
            closeModal={closeModal}
            dealId={dealId}
            documentId={documentId}
            fileAlias={alias}
            fileName={name}
            fileType={dealId ? 'Deal' : 'Document'}
            loading={modalLoading}
            setLoading={setModalLoading}
            setLoadingMessage={setLoadingMessage}
          />
        </div>
      </Modal>
    </>
  )
}

export default PageHeader
