import { API_ROOT } from '../../utils/apiUtils'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { FiUploadCloud } from 'react-icons/fi'
import { Formik } from 'formik'
import { captureError } from '../../utils/sentry'
import { useDocumentTypesQuery, useSimpleDealListQuery } from '../../graphql/codegen/hooks'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import Dropzone from '../Dropzone'
import Loader from '../Loader'
import Lottie from 'lottie-react'
import Modal from '../Modal'
import React, { useState } from 'react'
import SelectInput from '../SelectInput'
import animationData from '../../assets/lottie/document-scan.json'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'

export default function DocumentUploadModal() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setIsOpen(prev => !prev)} style={{ marginLeft: '8px' }}>
        <FiUploadCloud />
        <span style={{ marginLeft: 8 }}>Upload</span>
      </Button>
      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
        <InnerModal handleClose={() => setIsOpen(false)} />
      </Modal>
    </>
  )
}

function InnerModal({ handleClose }: { handleClose: () => void }) {
  const { data: dealsData, loading: dealsLoading } = useSimpleDealListQuery()
  const dealOptions = dealsData?.deals?.edges.map(edge => {
    const { alias, id, name } = edge?.node || {}
    return { label: alias || name, value: id }
  })

  const { data: docTypesData, loading: docTypesLoading } = useDocumentTypesQuery()
  const docTypeOptions = docTypesData?.document_types?.edges?.map(edge => {
    const { id, name } = edge?.node || {}
    return { label: name, value: id }
  })

  const isLoading = dealsLoading || docTypesLoading
  const [success, setSuccess] = useState(false)
  const { t } = useTranslation()

  if (success) {
    return <SuccessMessage goBack={() => setSuccess(false)} handleClose={handleClose} />
  }

  const contactButton = <a className={css.contactButton} href="mailto:sales@klaritylaw.com">{`Contact Sales`}</a>

  return isLoading ? (
    <Loader />
  ) : (
    <Formik
      initialValues={{ document: null, documentTypeId: null, dealName: null, dealId: null } as UploadDocumentArgs}
      onSubmit={async (values, { setFieldError, setSubmitting }) => {
        try {
          await uploadDocument(values)
          setSuccess(true)
        } catch (error: any) {
          captureError(error)
          setFieldError('general', error?.response?.data?.message || 'Something went wrong')
          setSubmitting(false)
        }
      }}
      validate={values => {
        const errors: { [k: string]: string } = {}
        if (!values.document) {
          errors.document = 'Required'
        }
        if (!values.dealName && !values.dealId) {
          errors.dealName = 'Required'
        }
        if (!values.documentTypeId) {
          errors.documentTypeId = 'Required'
        }
        return errors
      }}
    >
      {({ errors, handleSubmit, isSubmitting, setFieldValue, setValues, touched, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <ul className={css.onSubmitUL}>
              <li style={{ flex: 1, textAlign: 'center', padding: 16 }}>{`Document Upload`}</li>
              <li className={css.onSubmitLI} style={{ borderRight: '1px solid grey' }}>
                <span style={{ position: 'relative' }}>Batch Upload {contactButton}</span>
              </li>
              <li className={css.onSubmitLI}>
                <span style={{ position: 'relative' }}>Integrations {contactButton}</span>
              </li>
            </ul>
            <div style={{ padding: '32px 0 16px' }}>
              <div style={{ marginTop: 16 }}>
                <div style={{ fontWeight: 700 }}>{`Document`}</div>
                <Dropzone
                  accept=".docx,.pdf"
                  idleMessage="Drag and drop a .docx or .pdf document here, or click to select"
                  isError={!!(errors.document && touched.document && errors.document)}
                  multiple={false}
                  onDrop={(acceptedFiles: File[]) => setFieldValue('document', acceptedFiles[0])}
                />
                {values.document?.name && <span style={{ fontWeight: 400 }}>{values.document.name}</span>}
                <p className="error">{errors.document && touched.document && errors.document}</p>
              </div>
            </div>
            <div style={{ padding: '16px 0' }}>
              <label htmlFor="deal" style={{ fontWeight: 700 }}>
                {t('Deal')}
              </label>
              <SelectInput
                creatable
                isClearable
                isError={errors.dealName && (touched.dealName || touched.dealId) && errors.dealName}
                onChange={(option: { value: string } | null, { action }: { action: string }) => {
                  switch (action) {
                    case 'select-option':
                      return setValues((prev: any) => ({ ...prev, dealId: option?.value, dealName: null }))
                    case 'clear':
                      return setValues((prev: any) => ({ ...prev, dealId: null, dealName: null }))
                    case 'create-option':
                      return setValues((prev: any) => ({ ...prev, dealName: option?.value, dealId: null }))
                    default:
                      console.error(`Select action not supported: ${action}`)
                  }
                }}
                options={dealOptions}
                // @ts-ignore Formik infers what error state should look like based on initialState, here we have one error ('deal') for two keys in state because we either want one or the other.
                placeholder={`Select an existing ${t('deal')} or create a new one`}
              />
              {/* @ts-ignore See above for context */}
              <p className="error">{errors.deal && (touched.dealName || touched.dealId) && errors.deal}</p>
            </div>
            <div style={{ padding: '16px 0' }}>
              <label htmlFor="document type" style={{ fontWeight: 700 }}>{`Document Type`}</label>
              <SelectInput
                isError={errors.documentTypeId && touched.documentTypeId && errors.documentTypeId}
                onChange={({ value }: { value: string }) => setFieldValue('documentTypeId', value)}
                options={docTypeOptions}
                placeholder="Select document type"
              />
              <p className="error">{errors.documentTypeId && touched.documentTypeId && errors.documentTypeId}</p>
            </div>
            <div style={{ padding: '16px 0' }}>
              <Button htmlType="submit" loading={isSubmitting} style={{ width: '100%' }}>{`Upload`}</Button>
            </div>
            {/* @ts-ignore */}
            {errors.general && <p className="error">{errors.general}</p>}
          </form>
        )
      }}
    </Formik>
  )
}

type Maybe<T> = T | null

interface UploadDocumentArgs {
  dealId?: Maybe<string>
  dealName?: Maybe<string>
  document: Maybe<File>
  documentTypeId: Maybe<string>
}

function uploadDocument({ dealId, dealName, document, documentTypeId }: UploadDocumentArgs) {
  if (!dealId && !dealName) {
    throw Error('Must provide either dealName or dealId in order to upload document')
  } else if (dealId && dealName) {
    throw Error('Must provide one of either dealName or dealId, not both')
  }
  if (document === null || documentTypeId === null) {
    throw Error('document and documentTypeId must be present')
  }

  const formData = new FormData()
  formData.append('document', document)
  formData.append('document_type_id', documentTypeId)

  if (dealId) formData.append('deal_id', dealId)
  if (dealName) formData.append('deal_name', dealName)

  return axiosClient.post(`${API_ROOT}/document/upload`, formData)
}

function SuccessMessage({ goBack, handleClose }: { goBack: () => void; handleClose: () => void }) {
  const history = useHistory()
  const hasDealAccess = useUserAccess({ feature: Features.DEAL_DASHBOARD, permission: Permissions.READ })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h3>{`Success!`}</h3>
      <div className={css.lottieWrapper}>
        <Lottie animationData={animationData} style={{ height: 100 }} />
      </div>
      <p
        style={{ maxWidth: 400, textAlign: 'center' }}
      >{`Your document was successfully uploaded. You will be notified by email when it is done processing.`}</p>
      <div style={{ display: 'flex', width: '100%', marginTop: 16 }}>
        <Button onClick={goBack} style={{ flexGrow: 1 }}>{`Upload another`}</Button>
        <Button
          onClick={() => {
            history.push(hasDealAccess ? '/dashboard/documents' : '/')
            handleClose()
          }}
          style={{ flexGrow: 1 }}
          variant="secondary"
        >{`Go to documents`}</Button>
      </div>
    </div>
  )
}
