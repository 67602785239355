import { ArrowLeft, Download } from 'react-feather'
import { Attachment } from '../../graphql/codegen/schemas'
import { BasePDFDoc } from '../PDFDoc'
import { DealAttachmentsDocument, DocumentAttachmentsDocument } from '../../graphql/codegen/hooks'
import { Features, Permissions, useUserAccess } from '../../hooks/useUserAccess'
import { useApolloClient } from '@apollo/client'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import Loader from '../Loader'
import React, { useEffect, useState } from 'react'
import WithTooltip from '../WithTooltip'
import axiosClient from '../../utils/axiosClient'
import css from './style.module.scss'
import useAsync from '../../hooks/useAsync'

function useAttachment(id: string, dealId: string, documentId: string) {
  const [attachment, setAttachment] = useState<null | Attachment>(null)
  const client = useApolloClient()

  useEffect(() => {
    const query = dealId ? DealAttachmentsDocument : DocumentAttachmentsDocument
    const variables = dealId ? { dealId } : { documentId }
    client.query({ query, variables }).then(res => {
      const atts = dealId ? res.data.deal_attachments : res.data.document_attachments
      const att = atts.find((a: any) => a.id === id)
      setAttachment(att)
    })
  }, [id, documentId, dealId, client])

  return attachment
}

export default function AttachmentOverlay() {
  const [loading, setLoading] = useState(false)
  const [tooltipContent, setTooltipContent] = useState('')
  const history = useHistory()
  const { url } = useRouteMatch()
  const { attachmentId, dealId, documentId } = useParams<{ attachmentId: string; dealId: string; documentId: string }>()
  const prefix = dealId ? `/deals/${dealId}` : `/documents/${documentId}`
  const attachment = useAttachment(attachmentId, dealId, documentId)
  const isPdf = attachment?.name.slice(-4) === '.pdf' || attachment?.has_pdf_preview
  const hasDownloadAccess = useUserAccess({ feature: Features.ATTACHMENT, permission: Permissions.DOWNLOAD })

  const { data, error } = useAsync(async () => {
    if (!attachment?.id) return null
    const res = await axiosClient.get(`${prefix}/attachments/${attachmentId}?preview=1`, { responseType: 'blob' })
    return URL.createObjectURL(res.data)
  }, [attachment?.id])

  const handleDownload = async () => {
    if (attachment?.id && (attachment?.alias || attachment?.name)) {
      setLoading(true)
      const data = await axiosClient
        .get(`${prefix}/attachments/${attachment.id}`, {
          responseType: 'blob',
          onDownloadProgress(progressEvent) {
            const total = progressEvent.total ?? 0
            const progress = total ? Math.round((progressEvent.loaded / total) * 100) : 0

            setTooltipContent(`Downloading File: ${progress}%`)
          }
        })
        .then(res => {
          setLoading(false)
          setTooltipContent('')
          return res?.data
        })
        .catch(err => {
          setLoading(false)
          setTooltipContent('')
          return err
        })

      const href = URL.createObjectURL(data)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = href
      a.download = attachment?.alias || attachment?.name
      document.body.appendChild(a)
      a.click()
    }
  }

  return (
    <div className={css.overlay}>
      <div className={css.header}>
        <WithTooltip content="Close Preview" style={{ cursor: 'pointer' }}>
          <span aria-label="Close Preview" onClick={() => history.push(url.split('attachments')[0] + 'attachments')}>
            <ArrowLeft />
          </span>
        </WithTooltip>

        <span>{attachment?.alias || attachment?.name}</span>

        {hasDownloadAccess && (
          <WithTooltip content={tooltipContent || loading ? 'Downloading File…' : 'Download File'} style={{ cursor: 'pointer' }}>
            {loading ? <Loader variant="light" /> : <Download aria-label="Download File" onClick={handleDownload} />}
          </WithTooltip>
        )}
      </div>

      <div className={css.attachmentWrapper}>
        {error && <p>An error occurred. This file format may not be supported for preview.</p>}

        {!error && !data && <Loader variant="light" />}

        {data && (isPdf ? <BasePDFDoc data={data} error={error} /> : <img alt="" src={data} />)}
      </div>
    </div>
  )
}
