import { Document } from '../../graphql/codegen/schemas'
import { DocumentPageWrapper } from '../../components/DocumentPageWrapper'
import { DocumentPendingProcessingFragment } from '../../graphql/codegen/operations'
import { useDealsQuery } from '../../graphql/codegen/hooks'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import DataPointPanelContainer from '../../containers/DealDataPointPanelContainer'
import DealPageHeader from './DealPageHeader'
import ErrorPage from '../ErrorPage'
import Loader from '../../components/Loader'
import React from 'react'

export default function DealPage() {
  const { t } = useTranslation()

  const { dealId } = useParams<{ dealId: string }>()
  const { data, error, loading } = useDealsQuery({ variables: { id: dealId } })

  const deal = data?.deals?.edges?.[0]?.node
  const { alias, assignee, counter_party, created_at, documents, name, processing_documents, state } = deal || {}
  const documentNodes = documents?.edges?.map(docEdge => docEdge?.node as Document)
  const documentsPendingProcessing = (processing_documents || []) as DocumentPendingProcessingFragment[]
  const withoutDocument = !loading && !documentNodes

  if (loading) return <Loader />

  if (error?.message) return <ErrorPage message={`${t('Deal')} not found.\n\n${error.message}`} />

  if (!deal) return <ErrorPage message={`You do not have access to this ${t('deal')}.`} />

  return (
    <DocumentPageWrapper
      counterparty={counter_party}
      dataPointPanel={<DataPointPanelContainer dealIsFinalized={state?.final || false} documents={documentNodes} />}
      dealIsFinalized={state?.final || false}
      documents={documentNodes || []}
      documentsPendingProcessing={documentsPendingProcessing}
      headerComponent={
        <DealPageHeader
          alias={alias || undefined}
          assigneeTo={assignee?.user_name}
          counter_party={counter_party}
          created_at={created_at}
          dealIsFinalized={state?.final || false}
          name={name}
          state={state}
        />
      }
      loading={loading}
      name={alias || name}
      withDocumentSelector={!withoutDocument}
    />
  )
}
